.story-section {
  min-height: 749px;
  background-image: url("assets/images/story/story.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.story-section .slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}

.slider-image > img {
  width: 100%;
  height: 100%;
}

.slider .slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.slider-buttons > div {
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.slider-buttons > div.active {
  margin: 0 0.3rem;
  width: 14px;
  height: 14px;
}

.story-section .content {
  color: white;
  padding: 2rem;
  margin-top: 1rem;
  max-width: 645px;
}

.content > h2 {
  font-size: 36px;
  line-height: 62px;
}

.content > p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  margin-top: 0.8rem;
  line-height: 34px;
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .story-section {
    flex-direction: row-reverse;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding: 3rem;
  }

  .story-section .slider {
    /* width: 50%; */
    height: 524px;
    width: 489px;
  }

  .story-section .content {
    /* width: 50%; */
    text-align: left;
  }

  .content > h2 {
    font-size: 40px;
  }

  .content > p {
    font-size: 20px;
  }
}

/* Xl 1200  */
@media screen and (min-width: 1200px) {
}
