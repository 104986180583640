.contact-section {
  min-height: 400px;
  background-image: url("assets/images/contact.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  color: white;
  position: relative;
}

.contact-logo {
  font-size: 3rem;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  margin-bottom: 1rem;
}

.contact-logo > span {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: sans-serif;
}

.contact-section > .contact-us {
  font-size: 28px;
}

.contact-section > .email {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  margin-top: 1rem;
}

.contact-section .footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  padding: 0 2rem;
}

.contact-section .divider {
  width: 100%;
  border: 1px solid white;
  margin: 2rem;
}

.contact-section .copyright-text {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
}
