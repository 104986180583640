.about-section {
  min-height: 698px;
  background-image: url("assets/images/about.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.about-section > .image-container {
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container > .image {
  min-width: 200px;
  min-height: 200px;
  width: 300px;
  height: 300px;
  border: 3px solid white;
}

.image > img {
  width: 100%;
  height: 100%;
}

.about-section > .content {
  color: white;
  width: 100%;
  padding: 1rem 3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.content > .about-heading {
  font-size: 36px;
}

.content > .about-text {
  font-size: 16px;
  font-weight: 600;
}

.content > .foot-heading {
  line-height: 21px;
  font-size: 13px;
}

.content > ol {
  text-align: left;
  padding-left: 2rem;
  margin: 2rem 0;
}

ol > li {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 30px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 0.5rem;
}

.content > .icon-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.icon-buttons > a {
  color: #324251;
  padding: 0.2rem 1.3rem;
  height: 46px;
  margin: 0 0.2rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: white;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.icon-buttons > a > img {
}

.icon-btn > span {
  display: none;
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .about-section {
    flex-direction: row-reverse;
    padding: 4rem;
  }

  .about-section > .image-container {
    min-height: 450px;
    width: 500px;
    margin-left: 4rem;
  }

  .about-section > .content {
    max-width: 776px;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: left;
  }

  .content > .icon-buttons {
    justify-content: left;
    align-items: center;
  }

  .icon-buttons > a > img {
  }

  .icon-btn > span {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

/* Xl 1200  */
@media screen and (min-width: 1200px) {
  .image-container > .image {
    width: 443px;
    height: 443px;
  }
  .content > .about-heading {
    font-size: 40px;
  }

  .content > .about-text {
    font-size: 18px;
  }

  .content > ol {
    padding-left: 1.3rem;
  }

  ol > li {
    font-size: 18px;
  }

  .icon-buttons > a {
    margin-left: 2rem;
  }
}
