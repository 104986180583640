.team-section {
  min-height: 1300px;
  background: url("assets/images/team/team.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
}

/*Shocase*/
.team-section > .showcase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  width: 100%;
}

.showcase > h2 {
  color: white;
  margin: 2rem 0;
  margin-top: 5rem;
  font-size: 36px;
}

.showcase > .images {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.showcase-box {
}

.showcase-box > img.left,
img.right {
  width: 60px;
  position: absolute;
  cursor: pointer;
  bottom: -70px;
}

.showcase-box > img.left {
  left: 0;
  margin-left: 0.5rem;
}

.showcase-box > img.right {
  right: 0;
  margin-right: 0.5rem;
}

.images img.showcase-img {
  width: 236px;
  height: 262px;
}

.images .active-showcase {
  display: block;
  margin-bottom: 2rem;
}

.showcase > .indicators {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicators > div {
  width: 25px;
  background: rgba(255, 255, 255, 0.5);
  height: 6px;
  margin-left: 0.4rem;
}

.indicators > .active-indicator {
  background-color: white;
}

/* Team Members*/
.team-section > .team-members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}

.team-members > h2 {
  color: white;
  margin: 2rem 0;
  font-size: 36px;
}

.team-members > .members-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.indicators {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.indicators > div {
  width: 25px;
  background: rgba(255, 255, 255, 0.5);
  height: 6px;
  margin-left: 0.4rem;
}

.indicators > .active-indicator {
  background-color: white;
}

.none {
  display: none !important;
}

.block {
  display: block;
}

.members-list > .member-box {
  display: block;
}

.members-list > .active-member-box {
  display: block;
}

.member-box > .member-image-container {
  width: 303px;
  height: 303px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(137.8px);
  border: 1px solid rgb(190, 180, 180);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.member-image-container > img {
  width: 263px;
  height: 263px;
  z-index: 6;
  position: absolute;
}

.member-box > .content-and-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.member-box > .content-and-btn > .member-content {
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.content-and-btn > img.right {
  width: 60px;
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
  cursor: pointer;
  bottom: 0;
}

.content-and-btn > img.left {
  width: 60px;
  position: absolute;
  left: 0;
  margin-left: 0.5rem;
  cursor: pointer;
  bottom: 0;
}

.member-content > .member-name {
  font-size: 20px;
}

.member-content > .member-role {
  font-size: 14px;
  margin-top: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media screen and (min-width: 450px) {
  .content-and-btn > img.right {
    margin-right: 4rem;
  }

  .content-and-btn > img.left {
    margin-left: 4rem;
  }

  .showcase-box > img.left {
    margin-left: 4rem;
  }

  .showcase-box > img.right {
    margin-right: 4rem;
  }
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .content-and-btn > img {
    display: none;
  }

  .showcase-box > img.right,
  img.left {
    display: none;
  }

  .none {
    display: block !important;
  }

  .indicators {
    display: none !important;
  }
  .showcase > h2 {
    font-size: 40px;
    margin-top: 10rem;
  }

  .showcase > .images {
    display: flex;
    flex-direction: row;
  }

  .images img.showcase-img {
    width: 250px;
    height: 278px;
    display: block;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .images .active-showcase {
    display: block;
    margin-bottom: 0rem;
  }

  /*Team*/
  .team-members > .indicators {
    display: none;
  }

  .team-members > .members-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .members-list > .member-box {
    display: block;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .team-members > h2 {
    font-size: 40px;
  }

  .team-section {
    min-height: 1878px;
    border-bottom: 1px solid black;
  }
}

/* Xl 481 - 768 */
@media screen and (min-width: 1200px) {
  .team-section {
    min-height: 2000px;
  }
}
