.game-section {
  min-height: 465px;
  background-image: url("assets/images/game.png");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.game-section > p {
  color: white;
  padding: 0 3rem;
  line-height: 34px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  max-width: 1077px;
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .game-section > p {
    font-size: 20px;
  }
}

/* Xl 481 - 768 */
@media screen and (min-width: 1200px) {
}
