@font-face {
  font-family: "Minercraftory";
  src: url("fonts/Minecraftory/Minercraftory.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Minercraftory";
  scroll-behavior: smooth;
}
