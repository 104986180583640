.roadmap-section {
  /* min-height: 1554px; */
  min-height: 1200px;
  background-image: url("assets/images/roadmap/roadmap.png ");
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 0;
}

.roadmap-section > h2 {
  color: white;
  font-size: 36px;
  margin-bottom: 6rem;
}

.roadmap-section > .roadmap-details {
  min-height: 857px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.roadmap-details .roadmap-card {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  background: rgba(255, 255, 255, 0.61);
  backdrop-filter: blur(1px);
  position: absolute;
  min-width: 80px;
  line-height: 25px;
  width: 100%;
}

.roadmap-card > ul {
  margin-left: 3rem;
}

.roadmap-card > ul > li {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 0.9rem;
}

.roadmap-details .left-col {
  display: none;
}

.left-col > .desktop-card {
}

.roadmap-details .middle-col {
  min-height: 857px;
  width: 70px;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-col .roadmap-progress {
  text-align: center;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.roadmap-progress > div {
  position: absolute;
  color: white;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  background: url("assets/images/roadmap/Stone.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.roadmap-progress > .first {
  top: 88px;
}

.roadmap-progress > .second {
  top: 424px;
}

.roadmap-progress > .last {
  top: 734px;
}

.roadmap-details .right-col {
  min-width: 300px;
  width: 70%;
  max-width: 554px;
  min-height: 857px;
  /* padding: 1rem 3rem; */
  text-align: left;
  color: white;
  position: relative;
}

.right-col .first-card {
  top: 70px;
}

.right-col .second-card {
  top: 406px;
}

.right-col .last-card {
  top: 725px;
}

.middle-col > .mobile-card {
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
  .roadmap-details .roadmap-card {
    padding: 1.3rem 0rem;
    width: 100%;
  }

  .roadmap-card > ul > li {
    font-size: 1rem;
  }
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .roadmap-section {
    min-height: 1200px;
  }

  .roadmap-section > h2 {
    font-size: 40px;
  }

  .roadmap-details .left-col {
    display: block;
    min-height: 857px;
    width: 560px;
    position: relative;
    text-align: left;
    padding: 1rem 3rem;
  }

  .right-col .first-card {
    top: 30px;
  }

  .right-col .last-card {
    top: 660px;
  }

  .left-col > .desktop-card {
    right: 0;
    top: 380px;
  }

  .roadmap-details .middle-col {
    margin: 0 3rem;
  }

  .roadmap-details .roadmap-card {
    display: flex;
    padding: 1.4rem 1rem;
    background: rgba(255, 255, 255, 0.61);
    backdrop-filter: blur(1px);
    position: absolute;
    font-size: 27px;
    line-height: 44px;
    height: 180px;
  }

  .roadmap-details .right-col {
    width: 560px;
  }
  .right-col > .mobile-card {
    display: none;
  }
}

/* Xl 481 - 768 */
@media screen and (min-width: 1200px) {
}
