.nav-wrapper {
  position: fixed;
  width: 100%;
  z-index: 3;
}

.nav-container-desktop {
  display: none;
}

.nav-container-mobile {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.nav-container-mobile .nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-container-desktop .nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-buttons > .icon-btn {
  padding: 0.2rem 0.7rem;
  height: 46px;
  margin: 0 0.2rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mint-btn {
  color: #324251;
  padding: 0.2rem 1.3rem;
  height: 46px;
  margin: 0 0.2rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropbtn {
  background-color: white;
  color: white;
  height: 46px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 0 0.2rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  cursor: pointer;
}

.logo {
  font-size: 1.9rem;
  text-transform: uppercase;
  color: white;
  display: block;
  text-decoration: none;
}

.logo > span {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  font-family: sans-serif;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  border-radius: 10px 0 10px 10px;
  right: 0;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-weight: 300;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.block {
  display: block;
}

.menu {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu > img {
  width: 100%;
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .nav-container-desktop {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
  }

  .nav-container-mobile {
    display: none;
  }

  .nav-container-desktop .nav-links {
    display: flex;
    align-items: center;
  }

  .nav-container-desktop .nav-links .links {
    margin-left: 1rem;
  }

  .links a {
    color: white;
    text-decoration: none;
    margin-left: 2rem;
    font-size: 0.9rem;
  }
}

/* Xl 481 - 768 */
@media screen and (min-width: 1200px) {
  .nav-container-desktop {
    display: flex;
    justify-content: space-between;
    padding: 2rem 4rem;
  }
}
