.home-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section .hero-section {
  height: 100%;
  text-align: center;
}

.hero-section > h1 {
  color: white;
  font-size: 2.4rem;
  text-transform: capitalize;
  text-align: center;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.hero-section > button {
  height: 60px;
  background-color: white;
  cursor: pointer;
  margin-top: 1rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  border: none;
  color: #324251;
  padding: 0.2rem 1.3rem;
}

/* Sm 481 - 768 */
@media screen and (min-width: 481px) {
}

/* Md 769 - 1024 */
@media screen and (min-width: 769px) {
}

/* Lg 1025 - 1200 */
@media screen and (min-width: 1025px) {
  .hero-section > h1 {
    font-size: 3.5rem;
    width: 900px;
  }

  .hero-section > button {
    padding: 0.2rem 3rem;
    height: 76px;
  }
}

/* Xl 481 - 768 */
@media screen and (min-width: 1200px) {
}
